import * as tslib_1 from "tslib";
import { Expose } from 'class-transformer';
import { EntitySerialization } from '../../_helpers';
var NameEntity = /** @class */ (function (_super) {
    tslib_1.__extends(NameEntity, _super);
    function NameEntity() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    tslib_1.__decorate([
        Expose(),
        tslib_1.__metadata("design:type", String)
    ], NameEntity.prototype, "shortName", void 0);
    tslib_1.__decorate([
        Expose(),
        tslib_1.__metadata("design:type", String)
    ], NameEntity.prototype, "fullName", void 0);
    tslib_1.__decorate([
        Expose(),
        tslib_1.__metadata("design:type", String)
    ], NameEntity.prototype, "city", void 0);
    return NameEntity;
}(EntitySerialization));
export { NameEntity };
