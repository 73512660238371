import { TranslateService } from '@ngx-translate/core';
import { DefaultNoComponentGlobalConfig, ToastrService } from 'ngx-toastr';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "ngx-toastr";
import * as i2 from "@ngx-translate/core";
var ToastService = /** @class */ (function () {
    function ToastService(toastr, translate) {
        this.toastr = toastr;
        this.translate = translate;
    }
    ToastService.prototype.success = function (message, title, override) {
        this.toast([message, title], DefaultNoComponentGlobalConfig.iconClasses.success, override);
    };
    ToastService.prototype.info = function (message, title, override) {
        this.toast([message, title], DefaultNoComponentGlobalConfig.iconClasses.info, override);
    };
    ToastService.prototype.warning = function (message, title, override) {
        this.toast([message, title], DefaultNoComponentGlobalConfig.iconClasses.warning, override);
    };
    ToastService.prototype.error = function (message, title, override) {
        this.toast([message, title], DefaultNoComponentGlobalConfig.iconClasses.error, override);
    };
    ToastService.prototype.toast = function (keys, type, override) {
        var _this = this;
        this.translate
            .get(keys)
            .pipe(map(function (translated) { return Object.values(translated); }))
            .subscribe(function (translations) {
            _this.toastr.show(translations[0], translations[1], override, type);
        });
    };
    ToastService.ngInjectableDef = i0.defineInjectable({ factory: function ToastService_Factory() { return new ToastService(i0.inject(i1.ToastrService), i0.inject(i2.TranslateService)); }, token: ToastService, providedIn: "root" });
    return ToastService;
}());
export { ToastService };
