import * as tslib_1 from "tslib";
import { Exclude, Expose } from 'class-transformer';
import { BaseEntity } from '../../_helpers';
var ActivityConfigDetailEntity = /** @class */ (function () {
    function ActivityConfigDetailEntity() {
    }
    tslib_1.__decorate([
        Expose(),
        tslib_1.__metadata("design:type", String)
    ], ActivityConfigDetailEntity.prototype, "label", void 0);
    ActivityConfigDetailEntity = tslib_1.__decorate([
        Exclude()
    ], ActivityConfigDetailEntity);
    return ActivityConfigDetailEntity;
}());
export { ActivityConfigDetailEntity };
var ActivityConfigEntity = /** @class */ (function (_super) {
    tslib_1.__extends(ActivityConfigEntity, _super);
    function ActivityConfigEntity() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    tslib_1.__decorate([
        Expose(),
        tslib_1.__metadata("design:type", String)
    ], ActivityConfigEntity.prototype, "name", void 0);
    tslib_1.__decorate([
        Expose(),
        tslib_1.__metadata("design:type", String)
    ], ActivityConfigEntity.prototype, "activityType", void 0);
    ActivityConfigEntity = tslib_1.__decorate([
        Exclude()
    ], ActivityConfigEntity);
    return ActivityConfigEntity;
}(BaseEntity));
export { ActivityConfigEntity };
