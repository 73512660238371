import * as tslib_1 from "tslib";
import { Router } from '@angular/router';
import { get, trimEnd } from 'lodash';
import { StorageService, StorageTranscoders } from 'ngx-webstorage-service';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { AuthHttpClient } from './auth-http-client';
import { JwtHelperService } from '@auth0/angular-jwt';
import { UserRole } from '../core/enum/user-role.enum';
import { TranslateService } from '@ngx-translate/core';
import * as i0 from "@angular/core";
import * as i1 from "ngx-webstorage-service";
import * as i2 from "./auth-http-client";
import * as i3 from "@angular/router";
import * as i4 from "@ngx-translate/core";
var JWT_STORAGE_KEY = 'jwtTokenSessionKey';
var AuthService = /** @class */ (function () {
    function AuthService(storage, httpClient, router, translate) {
        this.storage = storage;
        this.httpClient = httpClient;
        this.router = router;
        this.translate = translate;
        this.jwtHelper = new JwtHelperService();
        this.apiUrl = trimEnd(environment.apiUrl, '/') + "/auth";
    }
    Object.defineProperty(AuthService.prototype, "isLoggedIn", {
        get: function () {
            return of(this.storage.has(JWT_STORAGE_KEY));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthService.prototype, "token", {
        get: function () {
            var session = this.storage.get(JWT_STORAGE_KEY, StorageTranscoders.JSON);
            return get(session, 'token');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthService.prototype, "loggedUsername", {
        get: function () {
            var session = this.storage.get(JWT_STORAGE_KEY, StorageTranscoders.JSON);
            return get(session, 'username');
        },
        enumerable: true,
        configurable: true
    });
    AuthService.prototype.login = function (credentials) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data, roles;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.httpClient.post(this.apiUrl + "/login", credentials).toPromise().catch(function () {
                            throw new Error(_this.translate.instant('Wrong username or password'));
                        })];
                    case 1:
                        data = _a.sent();
                        roles = this.jwtHelper.decodeToken(data.access_token).realm_access.roles;
                        if (roles.includes(UserRole.CLIENT)) {
                            this.storage.set(JWT_STORAGE_KEY, {
                                username: credentials.username,
                                token: data.access_token,
                                refreshToken: data.refresh_token
                            }, StorageTranscoders.JSON);
                            return [2 /*return*/, this.router.navigate(['/'])];
                        }
                        throw new Error(this.translate.instant('You are not authorized for entering this application'));
                }
            });
        });
    };
    AuthService.prototype.refreshToken = function () {
        var _this = this;
        var session = this.storage.get(JWT_STORAGE_KEY, StorageTranscoders.JSON);
        var refreshToken = get(session, 'refreshToken');
        return this.httpClient.post(this.apiUrl + "/refreshToken", {
            refreshToken: refreshToken
        }).pipe(map(function (data) {
            session.token = data.access_token;
            session.refreshToken = data.refresh_token;
            _this.storage.set(JWT_STORAGE_KEY, session, StorageTranscoders.JSON);
        }));
    };
    AuthService.prototype.logout = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.storage.remove(JWT_STORAGE_KEY);
                return [2 /*return*/, this.router.navigate(['/auth/login'])];
            });
        });
    };
    AuthService.prototype.isContractedCompanyUser = function () {
        return !this.isExternalCompanyUser();
    };
    AuthService.prototype.isExternalCompanyUser = function () {
        var contractIds = this.jwtHelper.decodeToken(this.token).contractId;
        return !contractIds || contractIds.length === 0;
    };
    AuthService.prototype.getUserId = function () {
        return this.jwtHelper.decodeToken(this.token).id;
    };
    AuthService.prototype.getCompanyId = function () {
        return this.jwtHelper.decodeToken(this.token).companyId;
    };
    AuthService.ngInjectableDef = i0.defineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.inject(i1.LOCAL_STORAGE), i0.inject(i2.AuthHttpClient), i0.inject(i3.Router), i0.inject(i4.TranslateService)); }, token: AuthService, providedIn: "root" });
    return AuthService;
}());
export { AuthService };
