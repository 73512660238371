/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./sortable-column.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./sortable-column.component";
var styles_SortableColumnComponent = [i0.styles];
var RenderType_SortableColumnComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SortableColumnComponent, data: {} });
export { RenderType_SortableColumnComponent as RenderType_SortableColumnComponent };
export function View_SortableColumnComponent_0(_l) { return i1.ɵvid(0, [i1.ɵncd(null, 0)], null, null); }
export function View_SortableColumnComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["sortableColumn", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).sort() !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_SortableColumnComponent_0, RenderType_SortableColumnComponent)), i1.ɵdid(1, 49152, null, 0, i2.SortableColumnComponent, [], { sortableColumn: [0, "sortableColumn"] }, null)], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 1, 0, currVal_0); }, null); }
var SortableColumnComponentNgFactory = i1.ɵccf("[sortableColumn]", i2.SortableColumnComponent, View_SortableColumnComponent_Host_0, { sortableColumn: "sortableColumn", sortableColumnField: "sortableColumnField" }, {}, ["*"]);
export { SortableColumnComponentNgFactory as SortableColumnComponentNgFactory };
