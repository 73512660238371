/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "./confirm-modal.component";
import * as i3 from "@ng-bootstrap/ng-bootstrap";
var styles_ConfirmModalComponent = [];
var RenderType_ConfirmModalComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ConfirmModalComponent, data: {} });
export { RenderType_ConfirmModalComponent as RenderType_ConfirmModalComponent };
export function View_ConfirmModalComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "h4", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(4, 0, null, null, 2, "button", [["class", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.dismiss() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u00D7"])), (_l()(), i0.ɵeld(7, 0, null, null, 3, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(9, null, ["", ""])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(11, 0, null, null, 7, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 2, "button", [["class", "btn btn-outline-danger"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.dismiss() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(13, null, [" ", " "])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵted(-1, null, [" \u00A0 "])), (_l()(), i0.ɵeld(16, 0, null, null, 2, "button", [["class", "btn btn-outline-success"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(17, null, [" ", " "])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform(_co.title)); _ck(_v, 2, 0, currVal_0); var currVal_1 = i0.ɵunv(_v, 9, 0, i0.ɵnov(_v, 10).transform(_co.description)); _ck(_v, 9, 0, currVal_1); var currVal_2 = i0.ɵunv(_v, 13, 0, i0.ɵnov(_v, 14).transform(_co.dismissButtonText)); _ck(_v, 13, 0, currVal_2); var currVal_3 = i0.ɵunv(_v, 17, 0, i0.ɵnov(_v, 18).transform(_co.submitButtonText)); _ck(_v, 17, 0, currVal_3); }); }
export function View_ConfirmModalComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-confirm-modal", [], null, null, null, View_ConfirmModalComponent_0, RenderType_ConfirmModalComponent)), i0.ɵdid(1, 49152, null, 0, i2.ConfirmModalComponent, [i3.NgbActiveModal], null, null)], null, null); }
var ConfirmModalComponentNgFactory = i0.ɵccf("app-confirm-modal", i2.ConfirmModalComponent, View_ConfirmModalComponent_Host_0, {}, {}, []);
export { ConfirmModalComponentNgFactory as ConfirmModalComponentNgFactory };
