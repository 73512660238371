import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { _t } from '../../_helpers';
import { DictionaryEntity } from '../../core/entity';
import { ScopeEnum } from '../../core/enum';
import { ApiConfigService, ToastService } from '../../core/services';
import { IncidentService } from '../incident.service';
var IncidentEditComponent = /** @class */ (function () {
    function IncidentEditComponent(fb, apiConfig, router, toast, activatedRoute, incidentService) {
        this.apiConfig = apiConfig;
        this.router = router;
        this.toast = toast;
        this.activatedRoute = activatedRoute;
        this.incidentService = incidentService;
        this.selectedLocation = new DictionaryEntity();
        this.incidentScope = ScopeEnum;
        this.incidentForm = fb.group({
            deviceId: ['', Validators.required],
            incidentTypeId: ['', Validators.required],
            description: [''],
            scope: [''],
            source: ['']
        });
    }
    IncidentEditComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.incident = this.activatedRoute.snapshot.data['incident'];
        this.apiConfig.locations$.subscribe(function (locations) {
            var location = locations.find(function (l) { return l.id === _this.incident.device.locationId; });
            if (location) {
                _this.selectedLocation = location;
            }
            _this.incidentForm.patchValue({
                deviceId: _this.incident.device.id,
                incidentTypeId: _this.incident.incidentType.id,
                description: _this.incident.description,
                scope: _this.incident.scope,
            });
        });
    };
    IncidentEditComponent.prototype.onSubmit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var incident;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.incidentService.save(this.incidentForm.value).toPromise()];
                    case 1:
                        incident = _a.sent();
                        return [4 /*yield*/, this.router.navigate(['/incident', incident.id])];
                    case 2:
                        _a.sent();
                        this.toast.success(_t('Incident successfully modified'), _t('Success!'));
                        return [2 /*return*/];
                }
            });
        });
    };
    return IncidentEditComponent;
}());
export { IncidentEditComponent };
