import {Component, OnInit} from '@angular/core';
import {_t} from '../../_helpers';
import {ToastService} from '../../core/services';
import {IncidentSocketService} from '../incident-socket.service';
import {IncidentService} from '../incident.service';
import {SortableSource} from '../../core/components/table/sortable-source';
import {IncidentEntity} from '../entity';
import {filter} from 'rxjs/operators';
import {AuthService} from '../../auth/auth.service';

@Component({
  selector: 'app-incident-list',
  templateUrl: './incident-list.component.html',
  styleUrls: ['./incident-list.component.scss']
})
export class IncidentListComponent implements OnInit {

  public sortableIncidents = new SortableSource<IncidentEntity>();
  public lastRefresh: Date;
  private filters = {};
  private incidents: IncidentEntity[] = [];

  constructor(
    private authService: AuthService,
    private toast: ToastService,
    private incidentService: IncidentService,
    private incidentSocket: IncidentSocketService
  ) {
    this.sortableIncidents.setCustomSort('created', 'desc', (t1, t2) => t2.created.getTime() - t1.created.getTime());
    this.sortableIncidents.setCustomSort('created', 'asc', (t1, t2) => t1.created.getTime() - t2.created.getTime());
    this.incidentSocket.messages
      .pipe(
        filter(incident => this.shouldShowIncident(incident))
      )
      .subscribe(incident => {
        this.toast.info(_t(`Location: ${incident.location}`), _t('New Incident'), {
          positionClass: 'toast-bottom-right',
          disableTimeOut: true,
          closeButton: true
        });
        this.incidents.unshift(incident);
        this.sortableIncidents.updateSource(this.incidents);
      });
  }

  public async ngOnInit(): Promise<void> {
    await this.loadIncidents();
  }

  public async doRefresh(): Promise<void> {
    await this.loadIncidents();
    this.toast.info(_t('Incidents refreshed!'));
  }

  public async doFilter(filterParam): Promise<void> {
    this.filters = {
      ...this.filters,
      ...filterParam
    };
    return this.loadIncidents();
  }

  private async loadIncidents(): Promise<void> {
    const incidents = await this.incidentService.find({
      params: {
        ...this.filters
      }
    }).toPromise();
    if (Array.isArray(incidents)) {
      this.incidents = incidents;
      this.sortableIncidents.updateSource(this.incidents);
      this.sortableIncidents.sort('created', 'desc');
    }
    this.lastRefresh = new Date();
  }

  private shouldShowIncident(incident: IncidentEntity) {
    return this.authService.getUserId() !== incident.reporter.id
      && !this.incidents.some(v => v.id === incident.id);
  }
}
