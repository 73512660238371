import {ModuleWithProviders, NgModule} from '@angular/core';
import {CoreModule} from '../core';
import {ThemeModule} from '../layout/theme';
import {IncidentCreateComponent} from './incident-create/incident-create.component';
import {IncidentEditComponent} from './incident-edit/incident-edit.component';
import {IncidentListComponent} from './incident-list/incident-list.component';
import {IncidentRoutingModule} from './incident-routing.module';
import {IncidentDetailComponent} from './incident-detail/incident-detail.component';
import {IncidentEntityResolver} from './incident-entity.resolver';
import {IncidentSocketService} from './incident-socket.service';

const COMPONENTS = [
  IncidentListComponent,
  IncidentCreateComponent,
  IncidentDetailComponent,
  IncidentEditComponent
];

const PROVIDERS = [
  IncidentEntityResolver,
  IncidentSocketService
];


@NgModule({
  imports: [
    ThemeModule,
    CoreModule,
    IncidentRoutingModule,
  ],
  exports: [...COMPONENTS],
  declarations: [...COMPONENTS],
  providers: [...PROVIDERS]
})
export class IncidentModule {
  static forRoot(): ModuleWithProviders {
    return <ModuleWithProviders>{
      ngModule: IncidentModule,
      providers: [...PROVIDERS]
    };
  }
}
