import {get} from 'lodash';
import {Expose, Type} from 'class-transformer';
import {BaseEntity} from '../../_helpers';
import {DeviceEntity} from './device.entity';
import {DocumentEntity, IncidentSource, IncidentTypeEntity, ScopeEnum, UserEntity} from '../../core';
import {Category} from '../../core/entity/category.entity';

export class IncidentEntity extends BaseEntity {

  @Expose()
  @Type(() => DeviceEntity)
  public device: DeviceEntity;

  @Expose()
  public description: string;

  @Expose()
  @Type(() => DocumentEntity)
  public attachments: DocumentEntity[];

  @Expose()
  public scope: ScopeEnum;

  @Expose()
  @Type(() => IncidentSource)
  public incidentSource: IncidentSource;

  @Expose()
  public reporter: UserEntity;

  @Expose()
  @Type(() => Date)
  public created: Date;

  @Expose()
  @Type(() => Date)
  public assigned: Date;

  @Expose()
  @Type(() => IncidentTypeEntity)
  public incidentType: IncidentTypeEntity;

  @Expose()
  public contractId: string;

  @Expose()
  public contractName: string;

  @Expose()
  @Type(() => Category)
  public category: Category;

  get location() {
    return get(this, 'device.locationName');
  }
}
