
    <form #f="ngForm" (submit)="onSelect()" novalidate>
      <div class="form-group">
        <select class="form-control" (change)="onSelect()" [compareWith]="myCompare" name="incident_type" [(ngModel)]="selectedType">
          <option [ngValue]="selectedType" disabled translate>Incident type</option>
          <option *ngFor="let type of types$ | async" [ngValue]="type" [selected]="type.id === selectedType?.id">
            {{type.name}}
          </option>
        </select>
      </div>
    </form>
  