import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
var LogoutComponent = /** @class */ (function () {
    function LogoutComponent(authService) {
        this.authService = authService;
    }
    LogoutComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.authService.logout()];
            });
        });
    };
    return LogoutComponent;
}());
export { LogoutComponent };
