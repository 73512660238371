import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import * as i0 from "@angular/core";
import * as i1 from "./auth.service";
import * as i2 from "@angular/router";
var ContractedUserGuard = /** @class */ (function () {
    function ContractedUserGuard(authService, router) {
        this.authService = authService;
        this.router = router;
    }
    ContractedUserGuard.prototype.canActivate = function () {
        var isContractedUser = this.authService.isContractedCompanyUser();
        if (!isContractedUser) {
            return this.router.parseUrl('/');
        }
        return isContractedUser;
    };
    ContractedUserGuard.ngInjectableDef = i0.defineInjectable({ factory: function ContractedUserGuard_Factory() { return new ContractedUserGuard(i0.inject(i1.AuthService), i0.inject(i2.Router)); }, token: ContractedUserGuard, providedIn: "root" });
    return ContractedUserGuard;
}());
export { ContractedUserGuard };
