import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ScopeEnum } from '../../core/enum';
import { IncidentTypesDictionaryService, LocationDictionaryService, ToastService } from '../../core/services';
import { IncidentEntity } from '../entity';
import { IncidentService } from '../incident.service';
import { CategoryDictionaryService } from '../../core/services/category-dictionary.service';
import { DeviceTypeDictionaryService } from '../../core/services/device-type-dictionary.service';
import { DeviceDictionaryService } from '../../core/services/device-dictionary.service';
import { IncidentSourceDictionaryService } from '../../core/services/source-dictuionary.service';
import { ContractDictionaryService } from '../../core/services/contract-dictionary.service';
import { _t } from '../../_helpers';
import { DictionaryEntity } from '../../core/entity';

@Component({
  selector: 'app-incident-create',
  templateUrl: './incident-create.component.html',
  styleUrls: ['./incident-create.component.scss']
})
export class IncidentCreateComponent implements OnInit {

  public incident: IncidentEntity;
  public incidentForm: FormGroup;

  public categories: DictionaryEntity[] = [];
  public contracts: DictionaryEntity[] = [];
  public incidentSources: DictionaryEntity[] = [];
  public deviceTypes: DictionaryEntity[] = [];
  public locations: DictionaryEntity[] = [];
  public incidentTypes: DictionaryEntity[] = [];
  public devices: DictionaryEntity[] = [];

  public showFormErrors = false;

  constructor(
    fb: FormBuilder,
    private router: Router,
    private toast: ToastService,
    private incidentService: IncidentService,
    private categoryService: CategoryDictionaryService,
    private contractService: ContractDictionaryService,
    private deviceTypeService: DeviceTypeDictionaryService,
    private locationService: LocationDictionaryService,
    private deviceDictionaryService: DeviceDictionaryService,
    private incidentTypesDictionaryService: IncidentTypesDictionaryService,
    private incidentSourceDictionaryService: IncidentSourceDictionaryService,
  ) {
    this.incidentForm = fb.group({
      contractId: ['', Validators.required],
      categoryId: ['', Validators.required],
      deviceTypeId: ['', Validators.required],
      locationId: ['', Validators.required],
      deviceId: ['', Validators.required],
      incidentTypeId: ['', Validators.required],
      incidentSourceId: ['', Validators.required],
      scope: [ScopeEnum.EXTERNAL, Validators.required],
      description: ['', Validators.required],
    });
  }

  public ngOnInit(): void {
    this.getContracts();
  }

  public async onContractChange(newValue: string) {
    this.incidentForm.patchValue({contractId: newValue});
    this.resetSelectsOnContractChange();
    this.categories = await this.categoryService.find({params: {contractId: newValue}}).toPromise();
    this.handleIfOneItem(this.categories, () => this.onCategoryChange(this.categories[0].id));
    this.incidentSources = await this.incidentSourceDictionaryService.find({params: {contractId: newValue}}).toPromise();
    this.handleIfOneItem(this.incidentSources, () => this.incidentForm.patchValue({incidentSourceId: this.incidentSources[0].id}));
  }

  public async onCategoryChange(newValue: string) {
    this.incidentForm.patchValue({categoryId: newValue});
    this.resetSelectsOnCategoryChange();
    this.deviceTypes = await this.deviceTypeService.find({params: {categoryId: newValue}}).toPromise();
    this.handleIfOneItem(this.deviceTypes, () => this.onDeviceTypeChange(this.deviceTypes[0].id));
  }

  public async onDeviceTypeChange(newValue: string) {
    this.incidentForm.patchValue({deviceTypeId: newValue});
    this.resetSelectsOnDeviceTypeChange();
    this.locations = await this.locationService.find({
      params: {
        deviceTypeId: newValue, categoryId: this.incidentForm.controls['categoryId'].value,
      }
    }).toPromise();
    this.handleIfOneItem(this.locations, () => this.onLocationChange(this.locations[0].id));
    this.incidentTypes = await this.incidentTypesDictionaryService.find({params: {deviceTypeId: newValue}}).toPromise();
    this.handleIfOneItem(this.incidentTypes, () => this.incidentForm.patchValue({incidentTypeId: this.incidentTypes[0].id}));
  }

  public async onLocationChange(newValue: string) {
    this.incidentForm.patchValue({locationId: newValue});
    this.resetSelectsOnLocationChange();
    this.devices = await this.deviceDictionaryService.find({
      params: {
        locationId: newValue,
        deviceTypeId: this.incidentForm.controls['deviceTypeId'].value
      }
    }).toPromise();
    this.handleIfOneItem(this.devices, () => this.incidentForm.patchValue({deviceId: this.devices[0].id}));
  }

  private resetSelectsOnContractChange() {
    this.incidentForm.patchValue({categoryId: ''});
    this.resetSelectsOnCategoryChange();
  }

  private resetSelectsOnCategoryChange() {
    this.incidentForm.patchValue({deviceTypeId: ''});
    this.resetSelectsOnDeviceTypeChange();
  }

  private resetSelectsOnDeviceTypeChange() {
    this.incidentForm.patchValue({locationId: ''});
    this.resetSelectsOnLocationChange();
  }

  private resetSelectsOnLocationChange() {
    this.incidentForm.controls['deviceId'].setValue('');
    this.incidentForm.controls['incidentTypeId'].setValue('');
  }

  public async onSubmit(): Promise<void> {
    if (this.incidentForm.valid) {
      this.showFormErrors = false;
      const {contractId, categoryId, deviceTypeId, locationId, ...payload} = this.incidentForm.value;
      const incident = await this.incidentService.save(payload).toPromise();
      await this.router.navigate(['/incident', incident.id]);
      this.toast.success(_t('Incident successfully created'), _t('Success!'));
    } else {
      this.showFormErrors = true;
    }
  }

  public get errorControls(): string[] {
    const invalidControlsNames = [];
    for (const name in this.incidentForm.controls) {
      if (this.incidentForm.controls[name].invalid) {
        invalidControlsNames.push(name);
      }
    }
    return invalidControlsNames;
  }

  private async getContracts(): Promise<void> {
    this.contracts = await this.contractService.find().toPromise();
    this.handleIfOneItem(this.contracts, () => this.onContractChange(this.contracts[0].id));
  }

  private handleIfOneItem<T>(value: Array<T>, handler: () => void): void {
    if (value && value.length === 1) {
      handler();
    }
  }
}
