import * as tslib_1 from "tslib";
import { Exclude, Expose, Type } from 'class-transformer';
import { BaseEntity } from '../../_helpers';
import { ActivityType } from '../enum/activity-type.enum';
import { WorkerEntity } from './worker.entity';
import { CarEntity } from './car.entity';
import { UserEntity } from './user.entity';
var ɵ0 = function () { return Date; }, ɵ1 = function () { return Date; }, ɵ2 = function () { return WorkerEntity; }, ɵ3 = function () { return CarEntity; };
var ActivityEntity = /** @class */ (function (_super) {
    tslib_1.__extends(ActivityEntity, _super);
    function ActivityEntity() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    tslib_1.__decorate([
        Expose(),
        Type(ɵ0),
        tslib_1.__metadata("design:type", Date)
    ], ActivityEntity.prototype, "createdDateTime", void 0);
    tslib_1.__decorate([
        Expose(),
        tslib_1.__metadata("design:type", String)
    ], ActivityEntity.prototype, "configActivityId", void 0);
    tslib_1.__decorate([
        Expose(),
        tslib_1.__metadata("design:type", String)
    ], ActivityEntity.prototype, "name", void 0);
    tslib_1.__decorate([
        Expose(),
        tslib_1.__metadata("design:type", UserEntity)
    ], ActivityEntity.prototype, "author", void 0);
    tslib_1.__decorate([
        Expose(),
        tslib_1.__metadata("design:type", String)
    ], ActivityEntity.prototype, "description", void 0);
    tslib_1.__decorate([
        Expose(),
        tslib_1.__metadata("design:type", Boolean)
    ], ActivityEntity.prototype, "internal", void 0);
    tslib_1.__decorate([
        Expose(),
        tslib_1.__metadata("design:type", String)
    ], ActivityEntity.prototype, "activityType", void 0);
    tslib_1.__decorate([
        Expose(),
        Type(ɵ1),
        tslib_1.__metadata("design:type", Date)
    ], ActivityEntity.prototype, "activityDateTime", void 0);
    tslib_1.__decorate([
        Expose(),
        Type(ɵ2),
        tslib_1.__metadata("design:type", Array)
    ], ActivityEntity.prototype, "workers", void 0);
    tslib_1.__decorate([
        Expose(),
        Type(ɵ3),
        tslib_1.__metadata("design:type", Array)
    ], ActivityEntity.prototype, "cars", void 0);
    tslib_1.__decorate([
        Expose(),
        tslib_1.__metadata("design:type", String)
    ], ActivityEntity.prototype, "companyId", void 0);
    ActivityEntity = tslib_1.__decorate([
        Exclude()
    ], ActivityEntity);
    return ActivityEntity;
}(BaseEntity));
export { ActivityEntity };
export { ɵ0, ɵ1, ɵ2, ɵ3 };
