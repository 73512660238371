import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { _t } from '../../_helpers';
import { ToastService } from '../../core/services';
import { IncidentSocketService } from '../incident-socket.service';
import { IncidentService } from '../incident.service';
import { SortableSource } from '../../core/components/table/sortable-source';
import { filter } from 'rxjs/operators';
import { AuthService } from '../../auth/auth.service';
var IncidentListComponent = /** @class */ (function () {
    function IncidentListComponent(authService, toast, incidentService, incidentSocket) {
        var _this = this;
        this.authService = authService;
        this.toast = toast;
        this.incidentService = incidentService;
        this.incidentSocket = incidentSocket;
        this.sortableIncidents = new SortableSource();
        this.filters = {};
        this.incidents = [];
        this.sortableIncidents.setCustomSort('created', 'desc', function (t1, t2) { return t2.created.getTime() - t1.created.getTime(); });
        this.sortableIncidents.setCustomSort('created', 'asc', function (t1, t2) { return t1.created.getTime() - t2.created.getTime(); });
        this.incidentSocket.messages
            .pipe(filter(function (incident) { return _this.shouldShowIncident(incident); }))
            .subscribe(function (incident) {
            _this.toast.info(_t("Location: " + incident.location), _t('New Incident'), {
                positionClass: 'toast-bottom-right',
                disableTimeOut: true,
                closeButton: true
            });
            _this.incidents.unshift(incident);
            _this.sortableIncidents.updateSource(_this.incidents);
        });
    }
    IncidentListComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.loadIncidents()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    IncidentListComponent.prototype.doRefresh = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.loadIncidents()];
                    case 1:
                        _a.sent();
                        this.toast.info(_t('Incidents refreshed!'));
                        return [2 /*return*/];
                }
            });
        });
    };
    IncidentListComponent.prototype.doFilter = function (filterParam) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.filters = tslib_1.__assign({}, this.filters, filterParam);
                return [2 /*return*/, this.loadIncidents()];
            });
        });
    };
    IncidentListComponent.prototype.loadIncidents = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var incidents;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.incidentService.find({
                            params: tslib_1.__assign({}, this.filters)
                        }).toPromise()];
                    case 1:
                        incidents = _a.sent();
                        if (Array.isArray(incidents)) {
                            this.incidents = incidents;
                            this.sortableIncidents.updateSource(this.incidents);
                            this.sortableIncidents.sort('created', 'desc');
                        }
                        this.lastRefresh = new Date();
                        return [2 /*return*/];
                }
            });
        });
    };
    IncidentListComponent.prototype.shouldShowIncident = function (incident) {
        return this.authService.getUserId() !== incident.reporter.id
            && !this.incidents.some(function (v) { return v.id === incident.id; });
    };
    return IncidentListComponent;
}());
export { IncidentListComponent };
