import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BaseService} from '../_helpers';
import {IncidentEntity} from './entity';

@Injectable({
  providedIn: 'root'
})
export class IncidentService extends BaseService<IncidentEntity> {

  constructor(protected http: HttpClient) {
    super('incidents', IncidentEntity);
  }
}
