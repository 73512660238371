import * as tslib_1 from "tslib";
import { BaseService } from '../../_helpers';
import { HttpClient } from '@angular/common/http';
import { DictionaryEntity } from '../entity';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ContractDictionaryService = /** @class */ (function (_super) {
    tslib_1.__extends(ContractDictionaryService, _super);
    function ContractDictionaryService(http) {
        var _this = _super.call(this, 'config/contracts/idAndNames', DictionaryEntity) || this;
        _this.http = http;
        return _this;
    }
    ContractDictionaryService.ngInjectableDef = i0.defineInjectable({ factory: function ContractDictionaryService_Factory() { return new ContractDictionaryService(i0.inject(i1.HttpClient)); }, token: ContractDictionaryService, providedIn: "root" });
    return ContractDictionaryService;
}(BaseService));
export { ContractDictionaryService };
