import { ApiConfigService } from './api-config.service';
import { CarService } from './car.service';
import { WorkerService } from './worker.service';
import { ContractDictionaryService } from './contract-dictionary.service';
import * as i0 from "@angular/core";
import * as i1 from "./api-config.service";
import * as i2 from "./car.service";
import * as i3 from "./worker.service";
import * as i4 from "./contract-dictionary.service";
var CoreService = /** @class */ (function () {
    function CoreService(apiConfigService, carService, workerService, contractDictionaryService) {
        this.apiConfigService = apiConfigService;
        this.carService = carService;
        this.workerService = workerService;
        this.contractDictionaryService = contractDictionaryService;
    }
    CoreService.prototype.onAppInit = function () {
        this.initCars();
        this.initWorkers();
        this.initContracts();
    };
    CoreService.prototype.initCars = function () {
        this.apiConfigService.cars$ = this.carService.find();
    };
    CoreService.prototype.initWorkers = function () {
        this.apiConfigService.workers$ = this.workerService.find();
    };
    CoreService.prototype.initContracts = function () {
        this.apiConfigService.contracts$ = this.contractDictionaryService.find();
    };
    CoreService.ngInjectableDef = i0.defineInjectable({ factory: function CoreService_Factory() { return new CoreService(i0.inject(i1.ApiConfigService), i0.inject(i2.CarService), i0.inject(i3.WorkerService), i0.inject(i4.ContractDictionaryService)); }, token: CoreService, providedIn: "root" });
    return CoreService;
}());
export { CoreService };
