import * as tslib_1 from "tslib";
import { Exclude, Expose } from 'class-transformer';
import { BaseEntity } from '../../_helpers';
var DictionaryEntity = /** @class */ (function (_super) {
    tslib_1.__extends(DictionaryEntity, _super);
    function DictionaryEntity() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    tslib_1.__decorate([
        Expose(),
        tslib_1.__metadata("design:type", String)
    ], DictionaryEntity.prototype, "name", void 0);
    DictionaryEntity = tslib_1.__decorate([
        Exclude()
    ], DictionaryEntity);
    return DictionaryEntity;
}(BaseEntity));
export { DictionaryEntity };
