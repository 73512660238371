import * as tslib_1 from "tslib";
import { Exclude, Expose } from 'class-transformer';
import { EntitySerialization } from './entity-serialization';
var BaseEntity = /** @class */ (function (_super) {
    tslib_1.__extends(BaseEntity, _super);
    function BaseEntity() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    tslib_1.__decorate([
        Expose(),
        tslib_1.__metadata("design:type", String)
    ], BaseEntity.prototype, "id", void 0);
    BaseEntity = tslib_1.__decorate([
        Exclude()
    ], BaseEntity);
    return BaseEntity;
}(EntitySerialization));
export { BaseEntity };
