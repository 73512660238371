import * as tslib_1 from "tslib";
import { HttpClient, HttpBackend } from '@angular/common/http';
var AuthHttpClient = /** @class */ (function (_super) {
    tslib_1.__extends(AuthHttpClient, _super);
    function AuthHttpClient(handler) {
        return _super.call(this, handler) || this;
    }
    return AuthHttpClient;
}(HttpClient));
export { AuthHttpClient };
