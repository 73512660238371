import * as tslib_1 from "tslib";
import { Exclude, Expose, Type } from 'class-transformer';
import { BaseEntity } from '../../_helpers';
import { GeoPointEntity } from './geo-point.entity';
import { NameEntity } from './name.entity';
var ɵ0 = function () { return NameEntity; }, ɵ1 = function () { return GeoPointEntity; };
var LocationEntity = /** @class */ (function (_super) {
    tslib_1.__extends(LocationEntity, _super);
    function LocationEntity() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    tslib_1.__decorate([
        Expose(),
        Type(ɵ0),
        tslib_1.__metadata("design:type", NameEntity)
    ], LocationEntity.prototype, "name", void 0);
    tslib_1.__decorate([
        Expose(),
        Type(ɵ1),
        tslib_1.__metadata("design:type", GeoPointEntity)
    ], LocationEntity.prototype, "geoPoint", void 0);
    LocationEntity = tslib_1.__decorate([
        Exclude()
    ], LocationEntity);
    return LocationEntity;
}(BaseEntity));
export { LocationEntity };
export { ɵ0, ɵ1 };
