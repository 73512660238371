import * as tslib_1 from "tslib";
import { get } from 'lodash';
import { Expose, Type } from 'class-transformer';
import { BaseEntity } from '../../_helpers';
import { DeviceEntity } from './device.entity';
import { DocumentEntity, IncidentSource, IncidentTypeEntity, ScopeEnum, UserEntity } from '../../core';
import { Category } from '../../core/entity/category.entity';
var ɵ0 = function () { return DeviceEntity; }, ɵ1 = function () { return DocumentEntity; }, ɵ2 = function () { return IncidentSource; }, ɵ3 = function () { return Date; }, ɵ4 = function () { return Date; }, ɵ5 = function () { return IncidentTypeEntity; }, ɵ6 = function () { return Category; };
var IncidentEntity = /** @class */ (function (_super) {
    tslib_1.__extends(IncidentEntity, _super);
    function IncidentEntity() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(IncidentEntity.prototype, "location", {
        get: function () {
            return get(this, 'device.locationName');
        },
        enumerable: true,
        configurable: true
    });
    tslib_1.__decorate([
        Expose(),
        Type(ɵ0),
        tslib_1.__metadata("design:type", DeviceEntity)
    ], IncidentEntity.prototype, "device", void 0);
    tslib_1.__decorate([
        Expose(),
        tslib_1.__metadata("design:type", String)
    ], IncidentEntity.prototype, "description", void 0);
    tslib_1.__decorate([
        Expose(),
        Type(ɵ1),
        tslib_1.__metadata("design:type", Array)
    ], IncidentEntity.prototype, "attachments", void 0);
    tslib_1.__decorate([
        Expose(),
        tslib_1.__metadata("design:type", String)
    ], IncidentEntity.prototype, "scope", void 0);
    tslib_1.__decorate([
        Expose(),
        Type(ɵ2),
        tslib_1.__metadata("design:type", IncidentSource)
    ], IncidentEntity.prototype, "incidentSource", void 0);
    tslib_1.__decorate([
        Expose(),
        tslib_1.__metadata("design:type", UserEntity)
    ], IncidentEntity.prototype, "reporter", void 0);
    tslib_1.__decorate([
        Expose(),
        Type(ɵ3),
        tslib_1.__metadata("design:type", Date)
    ], IncidentEntity.prototype, "created", void 0);
    tslib_1.__decorate([
        Expose(),
        Type(ɵ4),
        tslib_1.__metadata("design:type", Date)
    ], IncidentEntity.prototype, "assigned", void 0);
    tslib_1.__decorate([
        Expose(),
        Type(ɵ5),
        tslib_1.__metadata("design:type", IncidentTypeEntity)
    ], IncidentEntity.prototype, "incidentType", void 0);
    tslib_1.__decorate([
        Expose(),
        tslib_1.__metadata("design:type", String)
    ], IncidentEntity.prototype, "contractId", void 0);
    tslib_1.__decorate([
        Expose(),
        tslib_1.__metadata("design:type", String)
    ], IncidentEntity.prototype, "contractName", void 0);
    tslib_1.__decorate([
        Expose(),
        Type(ɵ6),
        tslib_1.__metadata("design:type", Category)
    ], IncidentEntity.prototype, "category", void 0);
    return IncidentEntity;
}(BaseEntity));
export { IncidentEntity };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6 };
