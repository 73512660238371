import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {IncidentCreateComponent} from './incident-create/incident-create.component';
import {IncidentDetailComponent} from './incident-detail/incident-detail.component';
import {IncidentEditComponent} from './incident-edit/incident-edit.component';
import {IncidentListComponent} from './incident-list/incident-list.component';
import {IncidentEntityResolver} from './incident-entity.resolver';

const routes: Routes = [
  {
    path: '',
    component: IncidentListComponent
  },
  {
    path: 'create',
    component: IncidentCreateComponent
  },
  {
    path: ':incident-id',
    component: IncidentDetailComponent,
    resolve: {
      incident: IncidentEntityResolver
    }
  },
  {
    path: ':incident-id/edit',
    component: IncidentEditComponent,
    resolve: {
      incident: IncidentEntityResolver
    }
  }
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class IncidentRoutingModule { }
