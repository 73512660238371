
    <form #f="ngForm" (submit)="onSelect()" novalidate>
      <div class="form-group">
        <select class="form-control" (change)="onSelect()" [compareWith]="myCompare" name="locations" [(ngModel)]="selectedLocation">
          <option [ngValue]="selectedLocation" disabled translate>Localization</option>
          <option *ngFor="let location of locations$ | async" [ngValue]="location" [selected]="location.id === selectedLocation?.id">
            {{location.name.fullName}}
          </option>
        </select>
      </div>
    </form>
  