import * as tslib_1 from "tslib";
import { Exclude, Expose } from 'class-transformer';
import { EntitySerialization } from '../../_helpers';
import { SeverityEnum } from '../enum';
var IncidentTypeEntity = /** @class */ (function (_super) {
    tslib_1.__extends(IncidentTypeEntity, _super);
    function IncidentTypeEntity() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    tslib_1.__decorate([
        Expose(),
        tslib_1.__metadata("design:type", String)
    ], IncidentTypeEntity.prototype, "id", void 0);
    tslib_1.__decorate([
        Expose(),
        tslib_1.__metadata("design:type", String)
    ], IncidentTypeEntity.prototype, "name", void 0);
    tslib_1.__decorate([
        Expose(),
        tslib_1.__metadata("design:type", String)
    ], IncidentTypeEntity.prototype, "severity", void 0);
    IncidentTypeEntity = tslib_1.__decorate([
        Exclude()
    ], IncidentTypeEntity);
    return IncidentTypeEntity;
}(EntitySerialization));
export { IncidentTypeEntity };
