import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ScopeEnum } from '../../core/enum';
import { IncidentTypesDictionaryService, LocationDictionaryService, ToastService } from '../../core/services';
import { IncidentService } from '../incident.service';
import { CategoryDictionaryService } from '../../core/services/category-dictionary.service';
import { DeviceTypeDictionaryService } from '../../core/services/device-type-dictionary.service';
import { DeviceDictionaryService } from '../../core/services/device-dictionary.service';
import { IncidentSourceDictionaryService } from '../../core/services/source-dictuionary.service';
import { ContractDictionaryService } from '../../core/services/contract-dictionary.service';
import { _t } from '../../_helpers';
var IncidentCreateComponent = /** @class */ (function () {
    function IncidentCreateComponent(fb, router, toast, incidentService, categoryService, contractService, deviceTypeService, locationService, deviceDictionaryService, incidentTypesDictionaryService, incidentSourceDictionaryService) {
        this.router = router;
        this.toast = toast;
        this.incidentService = incidentService;
        this.categoryService = categoryService;
        this.contractService = contractService;
        this.deviceTypeService = deviceTypeService;
        this.locationService = locationService;
        this.deviceDictionaryService = deviceDictionaryService;
        this.incidentTypesDictionaryService = incidentTypesDictionaryService;
        this.incidentSourceDictionaryService = incidentSourceDictionaryService;
        this.categories = [];
        this.contracts = [];
        this.incidentSources = [];
        this.deviceTypes = [];
        this.locations = [];
        this.incidentTypes = [];
        this.devices = [];
        this.showFormErrors = false;
        this.incidentForm = fb.group({
            contractId: ['', Validators.required],
            categoryId: ['', Validators.required],
            deviceTypeId: ['', Validators.required],
            locationId: ['', Validators.required],
            deviceId: ['', Validators.required],
            incidentTypeId: ['', Validators.required],
            incidentSourceId: ['', Validators.required],
            scope: [ScopeEnum.EXTERNAL, Validators.required],
            description: ['', Validators.required],
        });
    }
    IncidentCreateComponent.prototype.ngOnInit = function () {
        this.getContracts();
    };
    IncidentCreateComponent.prototype.onContractChange = function (newValue) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, _b;
            var _this = this;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        this.incidentForm.patchValue({ contractId: newValue });
                        this.resetSelectsOnContractChange();
                        _a = this;
                        return [4 /*yield*/, this.categoryService.find({ params: { contractId: newValue } }).toPromise()];
                    case 1:
                        _a.categories = _c.sent();
                        this.handleIfOneItem(this.categories, function () { return _this.onCategoryChange(_this.categories[0].id); });
                        _b = this;
                        return [4 /*yield*/, this.incidentSourceDictionaryService.find({ params: { contractId: newValue } }).toPromise()];
                    case 2:
                        _b.incidentSources = _c.sent();
                        this.handleIfOneItem(this.incidentSources, function () { return _this.incidentForm.patchValue({ incidentSourceId: _this.incidentSources[0].id }); });
                        return [2 /*return*/];
                }
            });
        });
    };
    IncidentCreateComponent.prototype.onCategoryChange = function (newValue) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.incidentForm.patchValue({ categoryId: newValue });
                        this.resetSelectsOnCategoryChange();
                        _a = this;
                        return [4 /*yield*/, this.deviceTypeService.find({ params: { categoryId: newValue } }).toPromise()];
                    case 1:
                        _a.deviceTypes = _b.sent();
                        this.handleIfOneItem(this.deviceTypes, function () { return _this.onDeviceTypeChange(_this.deviceTypes[0].id); });
                        return [2 /*return*/];
                }
            });
        });
    };
    IncidentCreateComponent.prototype.onDeviceTypeChange = function (newValue) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, _b;
            var _this = this;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        this.incidentForm.patchValue({ deviceTypeId: newValue });
                        this.resetSelectsOnDeviceTypeChange();
                        _a = this;
                        return [4 /*yield*/, this.locationService.find({
                                params: {
                                    deviceTypeId: newValue, categoryId: this.incidentForm.controls['categoryId'].value,
                                }
                            }).toPromise()];
                    case 1:
                        _a.locations = _c.sent();
                        this.handleIfOneItem(this.locations, function () { return _this.onLocationChange(_this.locations[0].id); });
                        _b = this;
                        return [4 /*yield*/, this.incidentTypesDictionaryService.find({ params: { deviceTypeId: newValue } }).toPromise()];
                    case 2:
                        _b.incidentTypes = _c.sent();
                        this.handleIfOneItem(this.incidentTypes, function () { return _this.incidentForm.patchValue({ incidentTypeId: _this.incidentTypes[0].id }); });
                        return [2 /*return*/];
                }
            });
        });
    };
    IncidentCreateComponent.prototype.onLocationChange = function (newValue) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.incidentForm.patchValue({ locationId: newValue });
                        this.resetSelectsOnLocationChange();
                        _a = this;
                        return [4 /*yield*/, this.deviceDictionaryService.find({
                                params: {
                                    locationId: newValue,
                                    deviceTypeId: this.incidentForm.controls['deviceTypeId'].value
                                }
                            }).toPromise()];
                    case 1:
                        _a.devices = _b.sent();
                        this.handleIfOneItem(this.devices, function () { return _this.incidentForm.patchValue({ deviceId: _this.devices[0].id }); });
                        return [2 /*return*/];
                }
            });
        });
    };
    IncidentCreateComponent.prototype.resetSelectsOnContractChange = function () {
        this.incidentForm.patchValue({ categoryId: '' });
        this.resetSelectsOnCategoryChange();
    };
    IncidentCreateComponent.prototype.resetSelectsOnCategoryChange = function () {
        this.incidentForm.patchValue({ deviceTypeId: '' });
        this.resetSelectsOnDeviceTypeChange();
    };
    IncidentCreateComponent.prototype.resetSelectsOnDeviceTypeChange = function () {
        this.incidentForm.patchValue({ locationId: '' });
        this.resetSelectsOnLocationChange();
    };
    IncidentCreateComponent.prototype.resetSelectsOnLocationChange = function () {
        this.incidentForm.controls['deviceId'].setValue('');
        this.incidentForm.controls['incidentTypeId'].setValue('');
    };
    IncidentCreateComponent.prototype.onSubmit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, contractId, categoryId, deviceTypeId, locationId, payload, incident;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!this.incidentForm.valid) return [3 /*break*/, 3];
                        this.showFormErrors = false;
                        _a = this.incidentForm.value, contractId = _a.contractId, categoryId = _a.categoryId, deviceTypeId = _a.deviceTypeId, locationId = _a.locationId, payload = tslib_1.__rest(_a, ["contractId", "categoryId", "deviceTypeId", "locationId"]);
                        return [4 /*yield*/, this.incidentService.save(payload).toPromise()];
                    case 1:
                        incident = _b.sent();
                        return [4 /*yield*/, this.router.navigate(['/incident', incident.id])];
                    case 2:
                        _b.sent();
                        this.toast.success(_t('Incident successfully created'), _t('Success!'));
                        return [3 /*break*/, 4];
                    case 3:
                        this.showFormErrors = true;
                        _b.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    Object.defineProperty(IncidentCreateComponent.prototype, "errorControls", {
        get: function () {
            var invalidControlsNames = [];
            for (var name_1 in this.incidentForm.controls) {
                if (this.incidentForm.controls[name_1].invalid) {
                    invalidControlsNames.push(name_1);
                }
            }
            return invalidControlsNames;
        },
        enumerable: true,
        configurable: true
    });
    IncidentCreateComponent.prototype.getContracts = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.contractService.find().toPromise()];
                    case 1:
                        _a.contracts = _b.sent();
                        this.handleIfOneItem(this.contracts, function () { return _this.onContractChange(_this.contracts[0].id); });
                        return [2 /*return*/];
                }
            });
        });
    };
    IncidentCreateComponent.prototype.handleIfOneItem = function (value, handler) {
        if (value && value.length === 1) {
            handler();
        }
    };
    return IncidentCreateComponent;
}());
export { IncidentCreateComponent };
