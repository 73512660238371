import {Injectable} from '@angular/core';
import {BaseService} from '../../_helpers';
import {DictionaryEntity} from '../entity';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DeviceDictionaryService extends BaseService<DictionaryEntity> {
  constructor(protected http: HttpClient) {
    super('config/devices/idAndNames', DictionaryEntity);
  }
}
