import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {DefaultNoComponentGlobalConfig, IndividualConfig, ToastrService} from 'ngx-toastr';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  constructor(
    private toastr: ToastrService,
    private translate: TranslateService
  ) {}

  public success(message?: string, title?: string, override?: Partial<IndividualConfig>): void {
    this.toast([message, title], DefaultNoComponentGlobalConfig.iconClasses.success, override);
  }

  public info(message?: string, title?: string, override?: Partial<IndividualConfig>): void {
    this.toast([message, title], DefaultNoComponentGlobalConfig.iconClasses.info, override);
  }

  public warning(message?: string, title?: string, override?: Partial<IndividualConfig>): void {
    this.toast([message, title], DefaultNoComponentGlobalConfig.iconClasses.warning, override);
  }

  public error(message?: string, title?: string, override?: Partial<IndividualConfig>): void {
    this.toast([message, title], DefaultNoComponentGlobalConfig.iconClasses.error, override);
  }

  private toast(keys, type, override): void {
    this.translate
      .get(keys)
      .pipe(map(translated => Object.values(translated)))
      .subscribe((translations: string[]) => {
        this.toastr.show(translations[0], translations[1], override, type);
      });
  }
}
