import * as tslib_1 from "tslib";
import { Expose, Type } from 'class-transformer';
import { BaseEntity } from '../../_helpers';
var ɵ0 = function () { return Date; };
var NoteEntity = /** @class */ (function (_super) {
    tslib_1.__extends(NoteEntity, _super);
    function NoteEntity() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    tslib_1.__decorate([
        Expose(),
        tslib_1.__metadata("design:type", String)
    ], NoteEntity.prototype, "username", void 0);
    tslib_1.__decorate([
        Expose(),
        tslib_1.__metadata("design:type", String)
    ], NoteEntity.prototype, "subject", void 0);
    tslib_1.__decorate([
        Expose(),
        tslib_1.__metadata("design:type", String)
    ], NoteEntity.prototype, "text", void 0);
    tslib_1.__decorate([
        Expose(),
        Type(ɵ0),
        tslib_1.__metadata("design:type", Date)
    ], NoteEntity.prototype, "created", void 0);
    return NoteEntity;
}(BaseEntity));
export { NoteEntity };
export { ɵ0 };
