import { IncidentService } from './incident.service';
import * as i0 from "@angular/core";
import * as i1 from "./incident.service";
var IncidentEntityResolver = /** @class */ (function () {
    function IncidentEntityResolver(incidentService) {
        this.incidentService = incidentService;
    }
    IncidentEntityResolver.prototype.resolve = function (route) {
        return this.incidentService.findById(route.paramMap.get('incident-id'));
    };
    IncidentEntityResolver.ngInjectableDef = i0.defineInjectable({ factory: function IncidentEntityResolver_Factory() { return new IncidentEntityResolver(i0.inject(i1.IncidentService)); }, token: IncidentEntityResolver, providedIn: "root" });
    return IncidentEntityResolver;
}());
export { IncidentEntityResolver };
