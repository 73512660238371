import * as tslib_1 from "tslib";
import { Exclude, Expose } from 'class-transformer';
import { BaseEntity } from '../../_helpers';
var WorkerEntity = /** @class */ (function (_super) {
    tslib_1.__extends(WorkerEntity, _super);
    function WorkerEntity() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(WorkerEntity.prototype, "fullName", {
        get: function () {
            return this.name + ' ' + (this.surname || '');
        },
        enumerable: true,
        configurable: true
    });
    tslib_1.__decorate([
        Expose(),
        tslib_1.__metadata("design:type", String)
    ], WorkerEntity.prototype, "name", void 0);
    tslib_1.__decorate([
        Expose(),
        tslib_1.__metadata("design:type", String)
    ], WorkerEntity.prototype, "surname", void 0);
    WorkerEntity = tslib_1.__decorate([
        Exclude()
    ], WorkerEntity);
    return WorkerEntity;
}(BaseEntity));
export { WorkerEntity };
