import {Component} from '@angular/core';
import {interval} from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'theme-sub-navbar',
  templateUrl: './sub-navbar.component.html',
  styleUrls: ['./sub-navbar.component.scss']
})
export class SubNavbarComponent {

  public now$ = interval(1000).pipe(map(() => new Date()));
}
