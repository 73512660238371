import * as tslib_1 from "tslib";
import { Expose } from 'class-transformer';
import { EntitySerialization } from '../../_helpers';
var GeoPointEntity = /** @class */ (function (_super) {
    tslib_1.__extends(GeoPointEntity, _super);
    function GeoPointEntity() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    tslib_1.__decorate([
        Expose(),
        tslib_1.__metadata("design:type", Number)
    ], GeoPointEntity.prototype, "latitude", void 0);
    tslib_1.__decorate([
        Expose(),
        tslib_1.__metadata("design:type", Number)
    ], GeoPointEntity.prototype, "longitude", void 0);
    return GeoPointEntity;
}(EntitySerialization));
export { GeoPointEntity };
