<div class="row justify-content-center m-0">
  <div class="card col-8">
    <div class="card-header">
      <h3>{{'New incident'|translate}}</h3>
    </div>
    <div class="card-body">
      <div *ngIf="!incidentForm.valid && showFormErrors" class="errors-container">
        <div class="errors-list"><strong>{{ 'Error!' | translate }}</strong> {{ 'Please fill required fields: ' | translate }}</div>
        <ul class="errors-list">
          <li *ngFor="let controlName of errorControls" [ngSwitch]="controlName">
            <ng-container *ngSwitchCase="'contractId'">{{'Contract'|translate}}</ng-container>
            <ng-container *ngSwitchCase="'categoryId'">{{'Category'|translate}}</ng-container>
            <ng-container *ngSwitchCase="'deviceTypeId'">{{'Device Type'|translate}}</ng-container>
            <ng-container *ngSwitchCase="'locationId'">{{'Location'|translate}}</ng-container>
            <ng-container *ngSwitchCase="'deviceId'">{{'Device'|translate}}</ng-container>
            <ng-container *ngSwitchCase="'incidentTypeId'">{{'Incident type'|translate}}</ng-container>
            <ng-container *ngSwitchCase="'incidentSourceId'">{{'Source'|translate}}</ng-container>
            <ng-container *ngSwitchCase="'description'">{{'Description'|translate}}</ng-container>
          </li>
        </ul>
      </div>
      <form id="incidentForm" [formGroup]="incidentForm" (ngSubmit)="onSubmit()" novalidate>
        <div class="form-row">
          <div class="form-group col-md-4">
            <select class="form-control large" (change)="onContractChange($event.target.value)" formControlName="contractId">
              <option [value]="''" disabled selected hidden>{{'Contract'|translate}}</option>
              <option *ngFor="let contract of contracts"
                      [value]="contract.id">
                {{contract.name}}
              </option>
            </select>
          </div>
          <div class="form-group col-md-4">
            <select class="form-control large" (change)="onCategoryChange($event.target.value)" formControlName="categoryId">
              <option [value]="''" disabled selected hidden>{{'Category'|translate}}</option>
              <option *ngFor="let category of categories"
                      [value]="category.id">
                {{category.name}}
              </option>
            </select>
          </div>
          <div class="form-group col-md-4">
            <select class="form-control large" (change)="onDeviceTypeChange($event.target.value)" formControlName="deviceTypeId">
              <option [value]="''" disabled selected hidden>{{'Device type'|translate}}</option>
              <option *ngFor="let deviceType of deviceTypes"
                      [value]="deviceType.id">
                {{deviceType.name}}
              </option>
            </select>
          </div>
          <div class="form-group col-md-4">
            <select class="form-control large" (change)="onLocationChange($event.target.value)" formControlName="locationId">
              <option [value]="''" disabled selected hidden>{{'Location'|translate}}</option>
              <option *ngFor="let location of locations"
                      [value]="location.id">
                {{location.name}}
              </option>
            </select>
          </div>
          <div class="form-group col-md-4">
            <select class="form-control large" formControlName="deviceId">
              <option value="" disabled hidden>{{'Device'|translate}}</option>
              <option *ngFor="let device of devices" [value]="device.id">
                {{device.name}}
              </option>
            </select>
          </div>
          <div class="form-group col-md-4">
            <select class="form-control large" formControlName="incidentTypeId">
              <option value="" disabled hidden>{{'Incident type'|translate}}</option>
              <option *ngFor="let incidentType of incidentTypes" [value]="incidentType.id">
                {{incidentType.name}}
              </option>
            </select>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-12">
            <select class="form-control large" formControlName="incidentSourceId">
              <option value="" disabled hidden>{{'Source'|translate}}</option>
              <option *ngFor="let incidentSource of incidentSources" [value]="incidentSource.id">
                {{incidentSource.name}}
              </option>
            </select>
          </div>
        </div>
        <div class="form-group">
          <textarea class="form-control" rows="6" placeholder="{{'Description'|translate}}"
                    formControlName="description"></textarea>
        </div>
      </form>
    </div>
    <div class="card-footer text-right">
      <button type="button" class="btn btn-outline-danger" [routerLink]="['/']">
        {{'Cancel'|translate}}
      </button>
      &nbsp;
      <button type="submit" form="incidentForm" class="btn btn-outline-success">
        {{'Submit'|translate}}
      </button>
    </div>
  </div>
</div>
