import * as tslib_1 from "tslib";
import { Exclude, Expose } from 'class-transformer';
import { BaseEntity } from '../../_helpers';
var DocumentEntity = /** @class */ (function (_super) {
    tslib_1.__extends(DocumentEntity, _super);
    function DocumentEntity() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    tslib_1.__decorate([
        Expose(),
        tslib_1.__metadata("design:type", String)
    ], DocumentEntity.prototype, "name", void 0);
    tslib_1.__decorate([
        Expose(),
        tslib_1.__metadata("design:type", String)
    ], DocumentEntity.prototype, "mimetype", void 0);
    tslib_1.__decorate([
        Expose(),
        tslib_1.__metadata("design:type", String)
    ], DocumentEntity.prototype, "url", void 0);
    tslib_1.__decorate([
        Expose(),
        tslib_1.__metadata("design:type", String)
    ], DocumentEntity.prototype, "description", void 0);
    tslib_1.__decorate([
        Expose(),
        tslib_1.__metadata("design:type", String)
    ], DocumentEntity.prototype, "category", void 0);
    tslib_1.__decorate([
        Expose(),
        tslib_1.__metadata("design:type", Date)
    ], DocumentEntity.prototype, "created", void 0);
    DocumentEntity = tslib_1.__decorate([
        Exclude()
    ], DocumentEntity);
    return DocumentEntity;
}(BaseEntity));
export { DocumentEntity };
