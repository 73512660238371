import * as tslib_1 from "tslib";
import { Exclude, Expose } from 'class-transformer';
import { BaseEntity } from '../../_helpers';
var CarEntity = /** @class */ (function (_super) {
    tslib_1.__extends(CarEntity, _super);
    function CarEntity() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(CarEntity.prototype, "name", {
        get: function () {
            return this.mark + ' ' + this.model + ' ' + (this.registrationNumber || '');
        },
        enumerable: true,
        configurable: true
    });
    tslib_1.__decorate([
        Expose(),
        tslib_1.__metadata("design:type", String)
    ], CarEntity.prototype, "mark", void 0);
    tslib_1.__decorate([
        Expose(),
        tslib_1.__metadata("design:type", String)
    ], CarEntity.prototype, "model", void 0);
    tslib_1.__decorate([
        Expose(),
        tslib_1.__metadata("design:type", Number)
    ], CarEntity.prototype, "costPerKm", void 0);
    tslib_1.__decorate([
        Expose(),
        tslib_1.__metadata("design:type", String)
    ], CarEntity.prototype, "registrationNumber", void 0);
    CarEntity = tslib_1.__decorate([
        Exclude()
    ], CarEntity);
    return CarEntity;
}(BaseEntity));
export { CarEntity };
