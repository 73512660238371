import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {IncidentEntity} from '../entity';

@Component({
  selector: 'app-incident-detail',
  templateUrl: './incident-detail.component.html',
  styleUrls: ['./incident-detail.component.scss']
})
export class IncidentDetailComponent implements OnInit {

  public incident: IncidentEntity;

  constructor(private activatedRoute: ActivatedRoute) {
  }

  public ngOnInit(): void {
    this.incident = this.activatedRoute.snapshot.data['incident'];
  }
}
