import {CommonModule} from '@angular/common';
import {ModuleWithProviders, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {NgbModalModule, NgbAccordionModule, NgbTimepickerModule} from '@ng-bootstrap/ng-bootstrap';
import {TranslateModule} from '@ngx-translate/core';
import {LoggedUserComponent, NavbarComponent, SubNavbarComponent} from './components';
import {SimplebarDirective} from './directives';
import {KeysPipe, TimeLeftPipe, SnakePipe} from './pipes';

const PIPES = [
  KeysPipe,
  SnakePipe,
  TimeLeftPipe
];

const DIRECTIVES = [
  SimplebarDirective
];

const BASE_MODULES = [
  CommonModule,
  FormsModule,
  RouterModule,
  ReactiveFormsModule,
  TranslateModule,
  NgbModalModule,
  NgbAccordionModule,
  NgbTimepickerModule
];

const COMPONENTS = [
  NavbarComponent,
  SubNavbarComponent,
  LoggedUserComponent
];

@NgModule({
  imports: [...BASE_MODULES],
  exports: [...BASE_MODULES, ...COMPONENTS, ...PIPES, ...DIRECTIVES],
  declarations: [...COMPONENTS, ...PIPES, ...DIRECTIVES]
})
export class ThemeModule {
  static forRoot(): ModuleWithProviders {
    return <ModuleWithProviders>{
      ngModule: ThemeModule,
      providers: [...DIRECTIVES]
    };
  }
}
