import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { BaseService } from '../../_helpers';
import { CarEntity } from '../entity';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var CarService = /** @class */ (function (_super) {
    tslib_1.__extends(CarService, _super);
    function CarService(http) {
        var _this = _super.call(this, 'config/cars', CarEntity) || this;
        _this.http = http;
        return _this;
    }
    CarService.ngInjectableDef = i0.defineInjectable({ factory: function CarService_Factory() { return new CarService(i0.inject(i1.HttpClient)); }, token: CarService, providedIn: "root" });
    return CarService;
}(BaseService));
export { CarService };
