import {Inject, Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';
import {Duration} from 'moment';
import {TranslateService} from '@ngx-translate/core';

@Pipe({name: 'durationFormat'})
export class DurationPipe implements PipeTransform {

  constructor(private translate: TranslateService) {}

  transform(value: any) {
    if (value === null) {
      return '';
    }
    const duration: Duration = moment.duration(value);
    let result = '';
    const days: number = Math.floor(duration.asDays());
    if (days > 0) {
      const daysText: string = (days === 1 ) ? this.translate.instant('day') : this.translate.instant('days');
      result += `${days} ${daysText}, `;
    }

    const hoursMinutesAndSeconds =  moment.utc(duration.as('milliseconds')).format('HH:mm:ss');
    return `${result} ${hoursMinutesAndSeconds}`;
  }
}
