import * as tslib_1 from "tslib";
import { BaseEntity } from '../../_helpers';
import { Expose } from 'class-transformer';
var IncidentSource = /** @class */ (function (_super) {
    tslib_1.__extends(IncidentSource, _super);
    function IncidentSource() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    tslib_1.__decorate([
        Expose(),
        tslib_1.__metadata("design:type", String)
    ], IncidentSource.prototype, "name", void 0);
    return IncidentSource;
}(BaseEntity));
export { IncidentSource };
