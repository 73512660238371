import {Pipe, PipeTransform} from '@angular/core';
import {Duration} from 'luxon';
import {timer} from 'rxjs';
import {map} from 'rxjs/operators';

@Pipe({name: 'timeLeft'})
export class TimeLeftPipe implements PipeTransform {
  private formater = ['d', 'h', `'`, `"`];

  transform(value: Date, args: string[]): any {
    /** @type Duration */
    return timer(0, 1000).pipe(map(() => {
      const duration = Duration.fromMillis(value.getTime() - Date.now());
      return duration
        .toFormat(`dd hh mm ss`)
        .split(' ')
        .map((k, i) => i !== 0 ? k.replace('-', '') : k)
        .map((k, i) => k + this.formater[i])
        .join(' ');
    }));
  }
}
