import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { BaseService } from '../../_helpers';
import { DictionaryEntity } from '../entity';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var LocationDictionaryService = /** @class */ (function (_super) {
    tslib_1.__extends(LocationDictionaryService, _super);
    function LocationDictionaryService(http) {
        var _this = _super.call(this, 'config/locations/idAndNames', DictionaryEntity) || this;
        _this.http = http;
        return _this;
    }
    LocationDictionaryService.ngInjectableDef = i0.defineInjectable({ factory: function LocationDictionaryService_Factory() { return new LocationDictionaryService(i0.inject(i1.HttpClient)); }, token: LocationDictionaryService, providedIn: "root" });
    return LocationDictionaryService;
}(BaseService));
export { LocationDictionaryService };
