import {Injectable} from '@angular/core';
import {deserialize} from 'class-transformer';
import {Subject} from 'rxjs';
import {environment} from '../../environments/environment';
import {WebSocketWrapper} from '../_helpers';
import {AuthService} from '../auth/auth.service';
import {IncidentEntity} from './entity';
import { map } from 'rxjs/operators';

@Injectable()
export class IncidentSocketService {
  private socket: WebSocketWrapper;
  public messages: Subject<IncidentEntity>;

  constructor(authService: AuthService) {
    this.socket = new WebSocketWrapper();
    this.messages = <Subject<IncidentEntity>>this.socket
      .connect(`${environment.socketUrl}/ws/incidents?access_token=${authService.token}`)
      .pipe(
        map((response: MessageEvent) => deserialize<IncidentEntity>(IncidentEntity, response.data))
      );
  }
}
