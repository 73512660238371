import * as tslib_1 from "tslib";
import { Expose } from 'class-transformer';
import { BaseEntity } from '../../_helpers';
var DeviceEntity = /** @class */ (function (_super) {
    tslib_1.__extends(DeviceEntity, _super);
    function DeviceEntity() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    tslib_1.__decorate([
        Expose(),
        tslib_1.__metadata("design:type", String)
    ], DeviceEntity.prototype, "shortName", void 0);
    tslib_1.__decorate([
        Expose(),
        tslib_1.__metadata("design:type", String)
    ], DeviceEntity.prototype, "name", void 0);
    tslib_1.__decorate([
        Expose(),
        tslib_1.__metadata("design:type", String)
    ], DeviceEntity.prototype, "deviceTypeId", void 0);
    tslib_1.__decorate([
        Expose(),
        tslib_1.__metadata("design:type", String)
    ], DeviceEntity.prototype, "deviceTypeName", void 0);
    tslib_1.__decorate([
        Expose(),
        tslib_1.__metadata("design:type", String)
    ], DeviceEntity.prototype, "locationId", void 0);
    tslib_1.__decorate([
        Expose(),
        tslib_1.__metadata("design:type", String)
    ], DeviceEntity.prototype, "locationName", void 0);
    return DeviceEntity;
}(BaseEntity));
export { DeviceEntity };
