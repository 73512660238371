import {CommonModule} from '@angular/common';
import {ModuleWithProviders, NgModule} from '@angular/core';
import {ThemeModule} from '../layout/theme';
import {ConfirmModalComponent, IncidentTypesComponent, LocationsComponent} from './components';
import {RequestCacheService} from './services/request-cache.service';
import { SortableColumnComponent } from './components/table/sortable-column.component';
import { LoaderComponent } from './components/loader/loader.component';
import {DurationPipe} from './pipes/DurationPipe';

const PROVIDERS = [
  RequestCacheService/* ,
  on purpose, for some reason, this stuff is not working, need to investigate more time
  {
    provide: HTTP_INTERCEPTORS,
    useClass: CacheInterceptor,
    multi: true
  } */
];

const COMPONENTS = [
  LocationsComponent,
  IncidentTypesComponent,
  ConfirmModalComponent,
  SortableColumnComponent,
  LoaderComponent,
  DurationPipe
];

@NgModule({
  imports: [
    ThemeModule,
    CommonModule
  ],
  declarations: [...COMPONENTS],
  providers: [...PROVIDERS],
  exports: [...COMPONENTS],
  entryComponents: [ConfirmModalComponent]
})
export class CoreModule {
  static forRoot(): ModuleWithProviders {
    return <ModuleWithProviders>{
      ngModule: CoreModule
    };
  }
}
