import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { BaseService } from '../../_helpers';
import { DictionaryEntity } from '../entity';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var IncidentTypesDictionaryService = /** @class */ (function (_super) {
    tslib_1.__extends(IncidentTypesDictionaryService, _super);
    function IncidentTypesDictionaryService(http) {
        var _this = _super.call(this, 'config/incidentTypes/idAndNames', DictionaryEntity) || this;
        _this.http = http;
        return _this;
    }
    IncidentTypesDictionaryService.ngInjectableDef = i0.defineInjectable({ factory: function IncidentTypesDictionaryService_Factory() { return new IncidentTypesDictionaryService(i0.inject(i1.HttpClient)); }, token: IncidentTypesDictionaryService, providedIn: "root" });
    return IncidentTypesDictionaryService;
}(BaseService));
export { IncidentTypesDictionaryService };
