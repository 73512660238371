const wsProtocol = {
  'https:': 'wss',
  'http:': 'ws'
};

export const environment = {
  production: true,
  apiUrl: `${location.origin}/api`,
  socketUrl: `${wsProtocol[location.protocol]}://${location.host}`
};
