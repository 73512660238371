import * as tslib_1 from "tslib";
import { plainToClass } from 'class-transformer';
import { trimEnd, get, defaultsDeep } from 'lodash';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
var BaseService = /** @class */ (function () {
    function BaseService(path, type) {
        this.path = path;
        this.type = type;
        this.apiUrl = trimEnd(environment.apiUrl, '/') + "/" + path;
    }
    Object.defineProperty(BaseService.prototype, "url", {
        get: function () {
            return this.apiUrl;
        },
        enumerable: true,
        configurable: true
    });
    BaseService.prototype.findById = function (id) {
        var _this = this;
        return this.http.get(this.apiUrl + "/" + id)
            .pipe(map(function (data) { return plainToClass(_this.type, data); }));
    };
    BaseService.prototype.find = function (options) {
        var _this = this;
        options = defaultsDeep(tslib_1.__assign({}, options), {
            params: {
                size: Number.MAX_SAFE_INTEGER.toString()
            }
        });
        return this.http.get("" + this.apiUrl, options)
            .pipe(map(function (data) { return plainToClass(_this.type, get(data, "_embedded." + _this.path.split('/').pop())); }));
    };
    BaseService.prototype.save = function (model, options) {
        var _this = this;
        var method = model.id ? 'put' : 'post';
        var url = model.id ? this.apiUrl + "/" + model.id : "" + this.apiUrl;
        options = defaultsDeep(tslib_1.__assign({}, options), {
            body: model
        });
        return this.http.request(method, url, options)
            .pipe(map(function (data) { return plainToClass(_this.type, data); }));
    };
    BaseService.prototype.delete = function (id) {
        return this.http.delete(this.apiUrl + "/" + this.path + "/" + id);
    };
    return BaseService;
}());
export { BaseService };
