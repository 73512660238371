import { AuthService } from 'src/app/auth/auth.service';
var NavbarComponent = /** @class */ (function () {
    function NavbarComponent(authService) {
        this.authService = authService;
    }
    Object.defineProperty(NavbarComponent.prototype, "isContractedUser", {
        get: function () {
            return this.authService.isContractedCompanyUser();
        },
        enumerable: true,
        configurable: true
    });
    return NavbarComponent;
}());
export { NavbarComponent };
